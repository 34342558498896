import React                from 'react'
import cx                   from 'utils/classnames'
import { map }              from 'lodash-es'
import Helmet               from "react-helmet"
import {
    Container,
}                           from 'react-bootstrap'
import Link                 from 'components/Link'
import Typography           from 'entrada-ui/Typography'
import TourCards            from 'components/TourCards'

const TourCard = TourCards.CardWp

const FourOhFour = ({
    pageContext: {
        data: {
            siteMetadata,
            related_tours,
            yoast_meta: {
                yoast_wpseo_social_defaults: {
                  og_default_image,
                  twitter_card_type
                }
              }
        }
    }
}) => {
    return (
        <>
            <Helmet>
                <title>Page not found - awesomeNZ</title>

                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />

                <meta property="og:title" content="Page not found - awesomeNZ" />
                <meta property="og:site_name" content={siteMetadata.title} />
                <meta property="og:image" content={og_default_image} />

                <meta name="twitter:card" content={twitter_card_type} />
                <meta name="twitter:title" content="Page not found - awesomeNZ" />
                <meta name="twitter:image" content={og_default_image} />
            </Helmet>
            <Container>
                <div className="text-center">
                    <h1>Sorry, page not found.</h1>
                    <br/>
                    <p>But with tours to NZ's most popular destinations, we can help you get back on track.</p>
                    <p>Return to our <Link to='/'>homepage</Link> or check out these top tours.</p>
                </div>

                <TourCards showArrows={false}>
                {map(related_tours, (tour, idx) => (
                    <TourCard
                        key={idx}
                        {...tour}
                    />
                ))}
                </TourCards>
            </Container>
        </>
    )
}

export default FourOhFour
